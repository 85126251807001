
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../extensions";
import "../../wdyr";
import "../../public/static/css/tailwind.css";
import { ApolloProvider } from "@apollo/client";
import { OverlayProvider } from "@react-aria/overlays";
import { SSRProvider } from "@react-aria/ssr";
import * as Sentry from "@sentry/nextjs";
import { FeatureFlag } from "@services/featureFlagConstants";
import { fetchFeatureFlags } from "@services/featureFlags";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import i18n from "i18next";
import moment from "moment";
import momentTZ from "moment-timezone";
import { AppProps } from "next/app";
import { setLanguage, useTranslation } from "ni18n";
import React, { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import { Cookie } from "src/constants/cookie";
import { useSyncReduxCookie } from "src/hooks/useSyncReduxCookie";
import { selectSelectedRegion } from "src/store/slices/userLocation";
import { ignoreArrays } from "src/utils/ignoreArrays";
import client from "../_services/graphql/client";
import { CarbonPage } from "../CarbonPage";
import { dev } from "../components/_common/_constants";
import { setMetadata, useEffectAsync } from "../components/_common/Carbon";
import { setBootState, setPracticeData, setPublicGoogleKeys, } from "../components/_common/setBootState";
import { MsMap } from "../constants/MsMap";
import { useGeolocateUser } from "../hooks/useGeolocateUser";
import { useSortLocations } from "../hooks/useSortLocations";
import { useStartSession } from "../hooks/useStartSession";
import { useSetKeyboardUser } from "../hooks/useWatchKeyboardUse";
import { languages } from "../languages";
import { NextReduxWrapper, actions, useTypedDispatch, useTypedSelector } from "../store";
import { freezeTime } from "../utils/freezeTime";
import { visitTracking } from "../utils/visitTracking";
import ErrorFallback from "./_error";
setUseWhatChange(dev);
i18n.use(initReactI18next).init();
type Props = AppProps & {
    maybeSkipSortLocations: boolean;
    clientIp: string | null;
    visitData: {
        visitId: string;
        visitorId: string;
    } | null;
};
function MyApp(props: Props) {
    const { Component, pageProps, maybeSkipSortLocations, router: { query: { freezeTimeValue }, }, clientIp, visitData, } = props;
    const { query } = props.router;
    const { lang } = useTranslation();
    const dispatch = useTypedDispatch();
    const { referer } = useTypedSelector(state => ({
        ...state,
        ...state.config,
    }));
    useStartSession(visitData?.visitId, visitData?.visitorId, clientIp || undefined, lang, referer);
    useSyncReduxCookie(Cookie.SELECTED_REGION, selectSelectedRegion);
    useEffectAsync(async () => {
        const lngFromQuery = query.lng as string;
        if (lngFromQuery) {
            if (languages.map(l => l.code).includes(lngFromQuery)) {
                await setLanguage(lngFromQuery);
                const { ...rest } = query;
                await props.router.replace({ query: rest }, undefined, { shallow: true });
            }
        }
        window.lng = window.__NEXT_DATA__.locale;
        if (lang && lang !== "en" && lang !== "en-US") {
            let lngPath = lang;
            if (lang === "zh") {
                lngPath = "zh-cn";
            }
            await import(`moment/locale/${lngPath}`);
            moment.locale(lngPath); // apply it to moment
            moment.updateLocale(lngPath, null); // copy locale to moment-timezone
            momentTZ.locale(lngPath); // apply it to moment-timezone
        }
        if (freezeTimeValue && typeof freezeTimeValue === "string") {
            freezeTime(parseInt(freezeTimeValue, 10));
        }
        fetchFeatureFlags({
            [FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED]: true,
        }, {
            query,
            dispatch,
        });
    }, []);
    useEffect(() => {
        if (referer) {
            dispatch(actions.setReferer(referer));
        }
        const id = visitData?.visitorId;
        if (id) {
            Sentry.setUser({ id });
        }
    }, [dispatch, referer, visitData?.visitorId]);
    useSetKeyboardUser();
    useGeolocateUser();
    useSortLocations(maybeSkipSortLocations);
    // force full reload every 3 hours to invalidate caches (eg: feature flags)
    useEffect(() => {
        setTimeout(() => {
            location.reload();
        }, MsMap.ONE_HOUR * 3);
    }, []);
    return <Component {...pageProps}/>;
}
const AppWrapper = (props: Props) => {
    const { store } = NextReduxWrapper.useWrappedStore(props);
    return (
    // @ts-expect-error TS2769: No overload matches this call.
    <Sentry.ErrorBoundary FallbackRender={ErrorFallback}>
      <SSRProvider>
        <ApolloProvider client={client}>
          <OverlayProvider>
            <ReduxProvider store={store}>
              <MyApp {...props}/>
            </ReduxProvider>
          </OverlayProvider>
        </ApolloProvider>
      </SSRProvider>
    </Sentry.ErrorBoundary>);
};
/**
 * Used to allow async functions to run in parallel,
 * but ensure that first argument will always be pageProps
 */
const getEmptyPromise = () => Promise.resolve({});
AppWrapper.getInitialProps = NextReduxWrapper.getInitialAppProps(store => async ({ Component, ctx }) => {
    const { getInitialProps = getEmptyPromise, optOutOfPracticeData } = Component as CarbonPage;
    const isServer = Boolean(ctx.req);
    const clientIp = ignoreArrays(ctx.req?.headers && ctx.req.headers["x-real-ip"]) ||
        ctx.req?.socket?.remoteAddress ||
        null;
    const shouldSetPracticeData = !optOutOfPracticeData;
    // must run before any feature flag evaluations
    const visitData = ctx.req && ctx.res ? await visitTracking({ req: ctx.req, res: ctx.res }) : null;
    if (shouldSetPracticeData)
        await setPracticeData(store.getState().config, store.dispatch);
    if (ctx.req)
        setBootState({ req: ctx.req, dispatch: store.dispatch, query: ctx.query });
    if (isServer)
        setPublicGoogleKeys(store.dispatch);
    const [pageProps] = await Promise.all([
        getInitialProps({ ...ctx, store }),
        setMetadata(ctx.pathname, ctx.query, store.dispatch),
    ]);
    return {
        pageProps: {
            ...pageProps,
        },
        maybeSkipSortLocations: optOutOfPracticeData,
        clientIp,
        visitData,
    };
});
const __Next_Translate__Page__1882bffbdde__ = AppWrapper;

    export default __appWithI18n(__Next_Translate__Page__1882bffbdde__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  