import "isomorphic-fetch";

import {isStagingDB, workersDataUrls} from "../components/_common/_constants";
import {LocationId} from "../constants/locations";
import {RootStateLocation, RootStatePractice} from "../store/types";

const fetchLocalLocations = (): Promise<RootStateLocation[]> =>
  // @ts-expect-error TS2322: Type 'Promise<({ id: string; practiceId: string; typ: string; coverImageId: string; name: string; phoneNumber: string; slug: string; address: { firstLine: string; city: string; state: string; zip: string; latitude: number; ... 4 more ...; secondLine?: undefined; }; ... 9 more ...; deactivatedAt?: undefined; } | ... 22 mor...' is not assignable to type 'Promise<RootStateLocation[]>'.
  import("../../public/static/data/localLocations.json").then(res => res.default);

const fetchLocalPractices = (): Promise<RootStatePractice[]> =>
  // @ts-expect-error TS2322: Type 'Promise<RootStatePractice[] | ({ id: string; name: string; address: { firstLine: string; city: string; state: string; zip: string; latitude: number; longitude: number; createdAt: number; id: string; }; ... 11 more ...; coverImageId: string; } | { ...; } | { ...; } | { ...; })[]>' is not assignable to type 'Promise<RootStatePractice[]>'.
  import("../../public/static/data/localPractices.json").then(res => res.default);

const fetchLocalData = async () => ({
  practices: await fetchLocalPractices(),
  locations: await fetchLocalLocations(),
});

export const validateWorkersDataResponse = (
  data: Awaited<ReturnType<typeof fetchWorkersData>>,
): Awaited<ReturnType<typeof fetchWorkersData>> => {
  const berkeleyClinic = data.locations.findById(LocationId.BERKELEY);
  // @ts-expect-error TS2532: Object is possibly 'undefined'.
  if (berkeleyClinic.address.state !== "CA") throw Error("Fetched workers data is bad.");
  return data;
};

const fetchWorkersData = (): Promise<{
  locations: RootStateLocation[];
  practices: RootStatePractice[];
}> => {
  const url = isStagingDB ? workersDataUrls.dev : workersDataUrls.prod;
  return fetch(url)
    .then(response => response.json())
    .then(validateWorkersDataResponse);
};

const defaultOptions = {
  fetchLocal: fetchLocalData,
  fetchWorkers: fetchWorkersData,
};

/**
 * Options are for testing only.
 */
export const fetchLocationsAndPractices = ({fetchWorkers, fetchLocal} = defaultOptions): Promise<{
  locations: RootStateLocation[];
  practices: RootStatePractice[];
}> => fetchWorkers().catch(fetchLocal);
