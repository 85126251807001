const {languages} = require("./src/languages.js");

module.exports = {
  defaultLocale: "en",
  locales: languages.map(l => l.code),
  defaultNS: "website",
  pages: {
    "rgx:^(?!/booking)": ["website", "website-db"],
    "rgx:^/booking": ["web-scheduling", "website-db"],
  },
  nsSeparator: false,
  keySeparator: false,
  logBuild: process.env.NODE_ENV !== "production",
  logger: () => null,
};
